<template>
<v-form v-model="contact_valid">
  <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #616161 !important" color="grey darken-4" elevation="6">
    <v-card-title class="justify-center">
      Contact Us
    </v-card-title>
    <v-card-subtitle>
      You can contact the Novarous team directly by filling out the form below.<br>
      Please allow at least 48 hours for a representative to reach out.
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text class="pb-0">
      <v-text-field
        v-model="contact_name"
        label="Name"
        :rules="name_rules"
        dense outlined
      />
      <v-text-field
        v-model="contact_email"
        label="E-mail"
        :rules="email_rules"
        dense outlined
      />
      <v-textarea
        v-model="contact_message"
        label="Message"
        placeholder="Briefly describe your reason for contacting support."
        :rules="message_rules"
        dense outlined
      />
      <v-alert type="error" class="mb-0 mt-4" v-show="contact_error" v-html="contact_error_msg"></v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-center pa-4">
      <v-btn color="primary" :disabled="!contact_valid" @click="submit_contact_form">
        Submit
      </v-btn>
    </v-card-actions>
    
    <!-- Loading Overlay -->
    <v-overlay :value="contact_state == 1" absolute>
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay :value="contact_state == 2" absolute>
      <p class="text-body-1">
        Request submitted.
      </p>
    </v-overlay>
  </v-card>
</v-form>
</template>
<script>
export default {
  name: 'ContactForm',
  data: () => ({
    // Contact form.
    contact_valid: false,
    contact_name: '',
    contact_email: '',
    contact_message: '',
    contact_state: 0,
    contact_error: false,
    contact_error_msg: '',

    name_rules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 32) || 'Name must be less than 32 characters',
    ],
    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'Must be a valid E-mail address.',
    ],
    message_rules: [
      v => !!v || 'Message is required',
      v => (v && v.length <= 500) || 'Message must be less than 500 characters',
    ]
  }),
  methods: {
    submit_contact_form: function() {
      this.contact_state = 1;

      const contact_endpoint = 'https://us-central1-id-novarous-web.cloudfunctions.net/api/submit-contact';
      this.$recaptcha('login').then((token) => {
        this.$http.post(contact_endpoint, {
          token: token, name: this.contact_name, email: this.contact_email, message: this.contact_message
        }).then(() => {
          this.contact_state = 2;
          this.contact_error = false;
        }).catch((error) => {
          this.contact_state = 0;
          this.contact_error = true;
          this.contact_error_msg = 'ReCaptcha check failed.<br>Please disable any adblockers and try again.';
          console.log(error);
        });
      });
    }
  }
}
</script>