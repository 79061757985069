<template>
  <v-main>
    <v-sheet id="support" color="grey darken-4" style="height: 100%" dark>
      <v-container class="nv-container py-8">
        <v-row justify="center">
          <v-col cols="12">
            <h4 class="text-h4 mb-4">
              Novarous Support
            </h4>
            <p class="text-subtitle-1 mb-0">
              Below are some commonly encountered issues and questions.<br>
              If you need to contact us directly, please use the form at the bottom of the page.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <license-recovery-form/>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <contact-form/>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-main>
</template>

<style>
#support {
  background: rgb(255,77,10);
  background: linear-gradient(135deg, rgba(255,77,10,0.9) 0%, rgba(21,63,212,0.9) 100%);
}
</style>

<script>
import LicenseRecoveryForm from '../components/LicenseRecoveryForm.vue';
import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'Support',
  components: {
    LicenseRecoveryForm,
    ContactForm
  }
}
</script>