<template>
<v-form v-model="contact_valid">
  <v-card class="rounded-lg overflow-hidden mb-4" style="border: 1px solid #616161 !important" color="grey darken-4" elevation="6">
    <v-card-title class="justify-center">
      License Recovery
    </v-card-title>
    <v-card-subtitle>
      You can have your license details re-sent to your e-mail address by entering it below.<br>
      Please allow at least 5 minutes for the e-mail to arrive.
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <v-text-field
        v-model="contact_email"
        label="E-mail"
        :rules="email_rules"
        dense hide-details outlined
      />
      <v-alert type="error" class="mb-0 mt-4" v-show="contact_error" v-html="contact_error_msg">
      </v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-center pa-4">
      <v-btn color="primary" :disabled="!contact_valid" @click="submit_contact_form">
        Submit
      </v-btn>
    </v-card-actions>

    <!-- Loading Overlay -->
    <v-overlay :value="contact_state == 1" absolute>
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay :value="contact_state == 2" absolute>
      <p class="text-body-1">
        Request submitted.
      </p>
    </v-overlay>
  </v-card>
</v-form>
</template>
<script>
export default {
  name: 'LicenseRecoveryForm',
  data: () => ({
    // Contact form.
    contact_valid: false,
    contact_email: '',
    contact_state: 0,
    contact_error: false,
    contact_error_msg: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'Must be a valid E-mail address.',
    ],
  }),
  methods: {
    submit_contact_form: function() {
      this.contact_state = 1;

      const contact_endpoint = 'https://us-central1-id-novarous-web.cloudfunctions.net/api/resend-license';
      this.$recaptcha('login').then((token) => {
        this.$http.post(contact_endpoint, {
          token: token, email: this.contact_email
        }).then(() => {
          this.contact_state = 2;
          this.contact_error = false;
        }).catch((error) => {
          this.contact_state = 0;
          this.contact_error = true;
          this.contact_error_msg = 'ReCaptcha check failed.<br>Please disable any adblockers and try again.';
          console.log(error);
        });
      });
    }
  }
}
</script>